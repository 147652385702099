<template>
  <div>
    <div class="center" v-if="list.length > 0">
      <div v-for="(item, index) in list" :key="index">
        <img :src="item.image" alt="" />
        <p @click="lingq(item)">领取</p>
      </div>
    </div>
    <div class="bottom" v-else>
      <img
        src="https://ppyos.xijiuyou.com/202201/rdskin-empty.jpg"
        alt=""
      />
      <p>您还没有领红包封面哦</p>
      <div @click="gocover">现在就去领</div>
    </div>
    <xwpopup :isshowpopup="links" @close="closelinks()" :hiddenClose="false">
      <div class="lingq">
        <p>领取步骤</p>
        <img
          src="https://ppyos.xijiuyou.com/202201/rdskin-invite-img.jpg"
          alt=""
        />
        <div
          @click="closelinks"
          class="tag-reads"
          :data-clipboard-text="clipboard"
        >
          确定
        </div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import game from "../../api/game";
import xwpopup from "../../components/Popupbox.vue";
import { Toast } from "vant";
import Clipboard from "clipboard";
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      list: [],
      links: false,
      clipboard: "",
    };
  },
  mounted() {
    this.myRecords();
  },
  methods: {
    gocover() {
      this.$router.push({
        name: "cover",
      });
    },
    myRecords() {
      game.myRecords({ page: 1, pageSize: 50 }).then((res) => {
        this.list = res.list;
      });
    },
    closelinks() {
      this.links = false;
      var clipboard = new Clipboard(".tag-reads");
      clipboard.on("success", () => {
        Toast("复制成功");
        try {
          if (window.android.isWXInstalled()) {
            window.android.shareToWX(
              0,
              3,
              "我要领限量版虎年红包封面",
              "这是我刚领到虎年微信红包封面，想要的话找我，免费送你哦！",
              "https://ppyos.xijiuyou.com/202201/to-tiger-draw.jpg",
              0,
              this.clipboard
            );
          } else {
            Toast("未安装微信");
          }
        } catch (e) {
          Toast("您当前app版本过低请更新app");
        }
      });
    },
    lingq(item) {
      this.links = true;
      this.clipboard = item.skinUrl;
    },
  },
};
</script>
<style scoped>
.center {
  width: 100%;
  min-height: 100vh;
  flex-wrap: wrap;
  padding-top: 25px;
}
.center div {
  width: 33%;
  text-align: center;
  margin-bottom: 30px;
  float: left;
}
.center div img {
  width: 93px;
}
.center div p {
  width: 94px;
  height: 40px;
  background: linear-gradient(90deg, #fec581 0%, #eaa059 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(201, 30, 0, 0.2);
  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 12px;
}
.lingq {
  width: calc(100% - 70px);
  background: #ffffff;
  border-radius: 15px;
  margin-top: 100px;
  padding: 15px 30px 30px;
}
.lingq p {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.lingq img {
  width: 100%;
  margin: 15px 0 20px;
}
.lingq div {
  width: 210px;
  height: 44px;
  background: linear-gradient(90deg, #fec581 0%, #eaa059 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(201, 30, 0, 0.2);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.bottom {
  padding-top: 150px;
  text-align: center;
}
.bottom img {
  width: 110px;
}
.bottom p {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 9px;
  margin-bottom: 40px;
}
.bottom div {
  width: 144px;
  height: 40px;
  background: linear-gradient(270deg, #ff674b 0%, #ff7c53 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(237, 0, 32, 0.4),
    inset 0px -1px 5px 0px rgba(255, 218, 162, 0.3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 0 auto;
}
</style>